import type { GetServerSideProps } from 'next';

import { title } from '@/domains/core/page/components/Error404/translations';
import type {
  InferPage,
  PageProps,
} from '@/domains/core/page/interfaces/PageProps';
import { getPagePropsAndUpdateResponse } from '@/domains/core/shell/page/getPagePropsAndUpdateResponse';

const pageType = 'page-error-404';

const Page404: InferPage<typeof getServerSideProps> = () => null;

export const getServerSideProps: GetServerSideProps<PageProps> = async (
  context,
) =>
  getPagePropsAndUpdateResponse(
    context,
    { notFound: true },
    {
      pageName: title,
      pageType,
    },
  );

export default Page404;
